/***********************************************
    Invenio App RDM Starter Item Overrides
***********************************************/

article > section#record-info {
  margin-bottom: 0.25rem;
}
article > .ui.divider.hidden {
  display: none;
}
section#content > .ui.items > .item > .content {
  font-family: Georgia, "Times New Roman", Times, serif !important;
  font-size: 16px !important;;
  line-height: 1.8 !important;

  figure {
    max-width: 100%;
    height: auto;
  }
  figure img, img:not(.inline-id-icon) {
    max-width: 100%;
    height: auto;
  }
  h2 {
    font-size: 1.7rem;
  }
  h3 {
    font-size: 1.4rem;
  }
  h4 {
    font-size: 16px !important;
  }
  p {
    line-height: 1.8 !important;
  }
  ul, ol {
    max-width: 100%;
  }
  figure .image-link-expand {
    display: none;
  }
  figcaption {
    font-style: italic;
  }
  a.d-print-none, .wp_orcid_field, .citation, #quarto-bibliography, #quarto-citation, 
  .subscription-widget-wrap-editor, .button-wrapper, svg.anchor-symbol, .wp-block-spacer, .body-image-wrapper {
    display: none;
  }
  h2#references, h3#references, h2#references + ol, h3#references + ol, h2#references + ul, h3#references + ul, h2 + .csl-bib-body {
    display: none;
  }
  .wp-block-syntaxhighlighter-code > pre {
    font-size: 0.9rem;
  }
}
