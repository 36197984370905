/***********************************************
         Invenio RDM Starter Header Overrides
***********************************************/

section#content .ui.items .item .content {
  h2, h2:not(#record-details-heading) {
    margin-top: 1.25rem;
    margin-bottom: 0.25rem;
  }
  h3 {
    margin-top: 1rem;
    margin-bottom: 0.25rem;
  }
  h4 {
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
  }
}
