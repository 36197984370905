/***********************************************
         Invenio RDM Starter Site Overrides
***********************************************/

html.cover-page {
  background-color: @grey;
}

code {
  color: @codeColor;
  background-color: @codeBackgroundColor;
  padding: 3px;
}

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 0.9rem;
}
